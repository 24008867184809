<template>
  <v-app>
    <v-app-bar-nav-icon
      v-if="!drawer"
      @click="drawer = !drawer"
      large
      class="primary--text ma-4"
    ></v-app-bar-nav-icon>
    <v-row>
      <v-navigation-drawer v-model="drawer" app :clipped="clipped" hide-overlay>
        <div class="pa-2">
          <v-btn text fab @click="drawer = !drawer">
            <v-icon color="primary">mdi-close</v-icon></v-btn
          >
        </div>
        <v-list-item class="pa-5">
          <v-icon large>mdi-account-circle</v-icon>

          <h2 class="ml-3">Admin Dashboard</h2>
        </v-list-item>

        <v-divider></v-divider>
        <div>
          <v-list dense>
            <v-list-item link :to="{ name: 'AdminsInfo' }">
              <v-card-subtitle> Admin</v-card-subtitle>
            </v-list-item>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="{ name: item.link }"
              color="info"
            >
              <v-card-subtitle>{{ item.title }}</v-card-subtitle>
            </v-list-item>
          </v-list>

          <p class="mx-2 mt-5" @click="logout">
            <v-btn block color="primary">logout</v-btn>
          </p>
        </div>
      </v-navigation-drawer>

      <v-main class="pa-4 mt-10">
        <router-view></router-view>
      </v-main>
    </v-row>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AdminDashboard",
  data: () => ({
    drawer: true,
    clipped: true,
    items: [
      {
        title: "Contacts",
        link: "ContactsInfo",
      },

      { title: "Trainees", link: "TraineesInfo" },

      {
        title: "Interns",
        link: "InternsInfo",
      },
      {
        title: "SkillUp",
        link: "SkillupInfo",
      },
      {
        title: "Kip",
        link: "KipInfo",
      },
      {
        title: "IT/Siwes",
        link: "SiwesInfo",
      },
    ],
  }),

  computed: {
    ...mapState(["curUser"]),
  },

  methods: {
    ...mapActions(["setLogin"]),

    logout() {
      this.loading = true;

      setTimeout(() => {
        this.$store.dispatch("logout");
        this.setLogin(false);
        this.$router.push({ name: "Home" });
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
